import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import Forgot from "./pages/Forgot";
import { useAppContext } from "./context/AppContext";
import { LoaderRing } from "./components/Loader";

export default function Router() {
  const {
    state: { dictionary },
  } = useAppContext();
  

  if (!dictionary) return <LoaderRing active />;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/pw/reset" element={<Reset />} />
        <Route path="/pw/forgot" element={<Forgot />} />
        <Route path="/login" element={<Login />} />
        <Route path="/macau" element={<Login isMacau />} />
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
function NotFound() {
  return <div>Page not found - re-check your url or visit our homepage</div>;
}
