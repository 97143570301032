import { useEffect, useState } from "react";
import { useAppContext } from "../context/AppContext";
import { getLanguages } from "../api";
import { Stack, Box, Typography, Menu, MenuItem } from '@newulife/common'
import { ReactComponent as DropdownSVG } from 'assets/svg/dropdown.svg';


export default function LanguageSelector() {
  // Context
  const {
    state: { locale },
    dispatch,
  } = useAppContext();
  // State
  const [languages, setLanguages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null)
  const currentLanguage = languages.find(
    ({ locale: _locale }) => locale === _locale
  );

  useEffect(() => {
    getLanguages().then((res = {}) => {
      const { recordset } = res;
      const blackListLanguageLocales = ["ja-JP"];
      const filteredLanguages = recordset.filter(
        ({ locale }) => !blackListLanguageLocales.includes(locale)
      );
      setLanguages(filteredLanguages);
    });
  }, []);

  const hasOtherLanguages = languages?.length > 0

  const handleChange = ( e, {locale} ) => {
    e.preventDefault()
    dispatch({
      type: "LOCALE_SET",
      locale,
    });
    setAnchorEl(null)
  };

  const handleOpen = e => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Render language selector after languages have been retrieved from db.
  if (!languages.length) {
    return null;
  }
  return (
    <div>
      <Stack
        data-testid="select-right-submenu"
        display="inline-grid"
        gap={1}
        gridAutoFlow="column"
        justifyContent="flex-start"
        alignItems="center"
        alignContent="center"
        htmlFor="lang-menu"
        aria-label="Language Selection"
        aria-haspopup="true"
        sx={{
          cursor: hasOtherLanguages ? 'pointer' : undefined,
        }}
        onClick={handleOpen}
      >
        <Typography
          variant="button"
          data-testid="langMenuValue"
          onClick={e => e.preventDefault()}
        >
          {currentLanguage?.name}
        </Typography>
        <Box
          display="inline-flex"
          sx={theme => ({
            transform: Boolean(anchorEl) ? 'rotate(-180deg)' : '',
            transition: theme.transitions.create(['transform']),
          })}
        >
          <DropdownSVG />
        </Box>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          marginTop: '10px',
        }}
      >
        {languages.map(o => (
          <MenuItem
            data-testid={`submenu-selection_${o.name}`}
            key={o.name}
            value={o.name}
            onClick={e => handleChange(e, o)}
          >
            {o.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
