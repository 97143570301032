import Header from './Header';
import {
  Box,
} from '@newulife/common'
import bgImg from 'assets/images/somaderm-waterline.jpg'

export default function Layout({ children }) {

  return (
    <Box
      sx={{
        color: 'neutralCool.dark',
        height: '100%',
        minHeight: '100vh',
        transition: 'all .2s ease',
        justifyContent: 'flex-start',
        position: 'relative',
        width: '100%',
        backgroundColor: '#fafafa',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundImage: {
          md: `url(${bgImg})`,
        },
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        '&:before': {
          content: '""',
          position: 'fixed',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          background: 'hsla(0, 0%, 100%, .5)',
          zIndex: 0,
        },
      }}
    >
      <Header />
      {children}
    </Box>
  );
}
