import { useAppContext } from "../context/AppContext";
import { Alert, Grid, Typography } from "@newulife/common";

export default function Message() {
  const {
    state: { message },
    dispatch,
  } = useAppContext();

  function close() {
    dispatch({
      type: "MESSAGE_CLEAR",
    });
  }

  function getSeverity() {
    switch (message.type) {
      case "info":
        return "info";
      case "success":
        return "success";
      case "error":
        return "error";
      default:
        return "warning";
    }
  }
  if (!message) return null;
  return (
      <Alert
        onClose={close}
        severity={getSeverity()}
        variant="standard"
      >
        <Typography variant="subhead1">{message.text}</Typography>
      </Alert>
  );
}
