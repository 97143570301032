import LanguageSelector from 'components/LanguageSelector';
import {
  Box,
  Stack,
  Typography,
} from '@newulife/common'
import { ReactComponent as Logo } from 'assets/svg/logo.svg';

export default function Header() {

  return (
    <Box
    as="nav"
    // className="site-nav"
    sx={{
      backgroundColor: '#fff',
      padding: 2,
      zIndex: 100,
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: {
        md: '0 4px 14px rgba(0,0,0,.15)',
      },
    }}
  >
    <Logo />
    <Stack
      direction="row"
      alignItems="center"
    >
      <LanguageSelector />
    </Stack>
    </Box>
  );
}
