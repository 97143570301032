// main
import React, { useEffect } from "react";
import Router from "./Router";
import { useAppContext } from "./context/AppContext";
// components
import getContentfulData from "./components/Dictionary";
import MainContent from "components/layout/MainContent";
import Page from "components/layout/Page";
// styles
import "./App.css";
import "./enrollment.css";
// import "./various.css";

function App() {
  const {
    state: { locale },
    dispatch,
  } = useAppContext();
  useEffect(() => {
    if (locale) {
      getContentfulData(locale).then((res) => {
        dispatch({
          type: "DICTIONARY_SET",
          dictionary: res,
        });
      });
    }
  }, [locale, dispatch]);

  return (
    <Page className="welcome-screen">
      <MainContent>
        <Router />
      </MainContent>
    </Page>
  );
}

export default App;
