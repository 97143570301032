import { useState, useEffect, useCallback } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  Stack,
  Link,
  Typography,
  TextField,
} from "@newulife/common";
import { login } from "../api";
import { useAppContext } from "../context/AppContext";
import { LoaderRing } from "../components/Loader";
import "./login.css";
import Message from "../components/Message";
import MarkdownLinksOnly from "../components/MarkdownLinksOnly";

export default function Login({ isMacau }) {
  // context
  const {
    state: {
      dictionary: { login: c_login },
    },
    actions: { setMessage },
  } = useAppContext();

  // state
  const queryParams = new URLSearchParams(window.location.search);
  const redirectQuery = queryParams.get("redirect") || "";
  const [form, set_form] = useState({
    "dist-id": "",
    password: "",
    redirectUrl: redirectQuery,
  });
  const [error, setError] = useState("");
  const [loader, set_loader] = useState(true);
  const guid = queryParams.get("guid") || "";
  const loggedRepGuid = queryParams.get("loggedRepGuid") || "";
  const onBehalfRepGuid = queryParams.get("onBehalfRepGuid") || "";
  const onBehalfCustomerGuid = queryParams.get("onBehalfCustomerGuid") || "";

  const handleGUIDLogin = useCallback(async () => {
    const { error, url } = await login({
      isMacau,
      guid,
      onBehalfRepGuid,
      loggedRepGuid,
      onBehalfCustomerGuid,
    });
    if (error) {
      set_loader(false);
      if (isMacau) {
        setError(error);
      } else {
        setMessage({
          text: error,
          type: "info",
        });
      }
    } else if (url) {
      window.location.replace(url);
    }
  }, [
    guid,
    onBehalfRepGuid,
    loggedRepGuid,
    onBehalfCustomerGuid,
    isMacau,
    setMessage,
  ]);

  useEffect(() => {
    if (guid || (loggedRepGuid && (onBehalfRepGuid || onBehalfCustomerGuid))) {
      handleGUIDLogin();
    } else {
      set_loader(false);
    }
  }, [guid, onBehalfRepGuid, loggedRepGuid, onBehalfCustomerGuid]);

  // contentful text
  const [
    text_login,
    text_distId,
    text_forgotPassword,
    ,
    // text_failToLoginMessage,
    text_submit,
    text_reset,
    text_no_account,
    text_guest,
    text_macau_error,
    text_macau_error_title,
    text_password,
    text_continue_message,
  ] = c_login;

  const {
    item2: ContinueIntro,
    item3: ContinueLink,
    item4: ContinueEnd,
  } = text_continue_message;

  // Generate link for continue shopping content
  const redirectLink = redirectQuery ? decodeURIComponent(redirectQuery).replace(/\/([^\/]*)\/?$/, '/$1-r/') : ''
  const ShopLink = "https://shop-us.newulife.com" + redirectLink

  // functions
  function handleChange(e) {
    const { id, value } = e.target;
    set_form({ ...form, [id]: value });
  }

  const emptyFields = !form["dist-id"] || !form.password;

  function handleSubmit(e) {
    set_loader(true);
    e.preventDefault();
    if (!form["dist-id"] || !form.password) {
      const errorMessage = "Please add your Distributor ID and Password";
      if (isMacau) {
        setError(errorMessage);
      } else {
        setMessage({ text: errorMessage, type: "error" });
      }
      set_loader(false);
      return;
    }
    login({ ...form, isMacau }).then(({ error, url }) => {
      if (error) {
        if (isMacau) {
          setError(error);
        } else {
          setMessage({ text: error, type: "error" });
        }
        set_loader(false);
      } else if (url) {
        window.location.replace(url);
      }
    });
  }

  // render
  // if (loader && !isMacau) return <LoaderRing active={loader} />;
  return (
    <section>
      <h1 className="text-center mb-40 login-text mt-40">{text_login}</h1>
      <form
        className="form-max"
        onKeyUp={(e) => {
          if (e.key === "Enter") handleSubmit(e);
        }}
      >
        <Stack spacing={4} alignItems="center">
          <Stack spacing={2} className="form-max">
            <TextField
              id="dist-id"
              color="secondary"
              errorText="Please fill out your Distributor ID"
              label={text_distId}
              onChange={handleChange}
              required
              value={form["dist-id"]}
              variant="outlined"
            />
            <TextField
              id="password"
              color="secondary"
              errorText="Please fill out your password"
              label={text_password}
              onChange={handleChange}
              type="password"
              required
              value={form.password}
              variant="outlined"
            />
          </Stack>

          <Stack spacing={2} alignItems="center">
            <Button
              color="primary"
              size="medium"
              variant="contained"
              onClick={handleSubmit}
              disabled={loader || emptyFields}
            >
              {loader ? <LoaderRing active variant="small" /> : text_submit}
            </Button>
            <Message />
            {error && (
              <Alert
                onClose={() => setError("")}
                message={text_macau_error}
                severity="error"
                variant="standard"
              >
                <Grid gap={0.5}>
                  <Typography variant="subhead1">
                    {text_macau_error_title}
                  </Typography>
                  <Typography variant="body2">{text_macau_error}</Typography>
                </Grid>
              </Alert>
            )}

            <Link color="primary" href="/pw/forgot" underline="hover">
              {text_forgotPassword}
            </Link>
          </Stack>
          <Box>
            <p className="text-center">
              <MarkdownLinksOnly content={ContinueIntro} /> <Link color="primary" href={ShopLink} underline="hover">{ContinueLink}</Link> <MarkdownLinksOnly content={ContinueEnd} />
            </p>
          </Box>
        </Stack>

        {/* <a href="/pw/forgot" className="mt-40">
          {text_forgotPassword}
        </a> */}
        {/* <a href="/pw/reset">{text_reset}?</a> */}
        {/* <div className="text-center">
          <div>{text_no_account}</div>
          <a href="https://store.newulife.com" className="mt-40">
            {text_guest}
          </a>
        </div> */}
      </form>
    </section>
  );
}
