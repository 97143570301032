const THEME_SET = "THEME_SET";
const LOCALE_SET = "LOCALE_SET";
const DICTIONARY_SET = "DICTIONARY_SET";
const MESSAGE_SET = "MESSAGE_SET";
const MESSAGE_CLEAR = "MESSAGE_CLEAR";
const RESET = "RESET";

export const initialState = {
  theme: "light",
  locale: "en-US",
  message: null,
  dictionary: null,
};

const reducer = (state, action) => {
  const newState = { ...state };
  switch (action.type) {
    // THEME ACTIONS
    case THEME_SET:
      return { ...newState, theme: action.theme };
    // LOCALE ACTIONS
    case LOCALE_SET:
      return { ...newState, locale: action.locale };
    // MESSAGE ACTIONS
    case MESSAGE_SET:
      return { ...newState, message: action.message };
    case MESSAGE_CLEAR:
      return { ...newState, message: null };

    // DICTIONARY ACTIONS
    case DICTIONARY_SET:
      return { ...newState, dictionary: action.dictionary };

    // UNIVERSAL ACTIONS
    case RESET:
      return { ...initialState };

    default:
      return { ...newState };
  }
};

export default reducer;
